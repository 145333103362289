import { useCallback, useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import Checkbox from '@material-ui/core/Checkbox';
import { useMutation, useQuery } from '@apollo/client';
import { CLIENTS_QUERY, SETTINGS_QUERY } from 'gql/queries';
import ClientSkeleton from './ClientSkeleton';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';

import { NavLink, useHistory } from 'react-router-dom';

import { FixedSizeList as WindowedList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { DELETE_CLIENT } from '../../gql/mutations';

const useStylesDot = makeStyles((theme) => ({
  dot: {
    backgroundColor: ({ active }) =>
      active ? theme.palette.success.main : theme.palette.error.main,
    width: 8,
    height: 8,
    borderRadius: '50%',
  },
}));

const ClientDot = ({ active }) => {
  const classes = useStylesDot({ active });

  return <div className={classes.dot} />;
};


const useTrashStyles = makeStyles((theme) => ({
  redTrash: {
    //color: theme.palette.error.main,
  },
}));

const ClientTrash = ({ onDelete }) => {
  const { loading, data, refetch } = useQuery(SETTINGS_QUERY);
  const CHECK_GLOBAL_PASSWORD_PASSWORD = data?.settings?.globalDeleteKey || null;
  const [openFirstDialog, setOpenFirstDialog] = useState(false);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const classes = useTrashStyles();
  const handleFirstDialogOpen = useCallback(() => {
    setOpenFirstDialog(true);
  }, []);

  const handleFirstDialogClose = useCallback(() => {
    setOpenFirstDialog(false);
  }, []);

  const handleSecondDialogOpen = useCallback(() => {
    setOpenFirstDialog(false);
    setOpenSecondDialog(true);
  }, []);

  const handleSecondDialogClose = useCallback(() => {
    setOpenSecondDialog(false);
    setPassword('');
    setError('');
  }, []);

  const handlePasswordChange = useCallback((event) => {
    setPassword(event.target.value);
  }, []);

  const handleConfirmDelete = useCallback(() => {
    if (CHECK_GLOBAL_PASSWORD_PASSWORD !== null && password === CHECK_GLOBAL_PASSWORD_PASSWORD) {
      if (typeof onDelete === 'function') {
        onDelete();
      } else {
        console.log('onDelete is not implemented');
      }
      handleSecondDialogClose();
    } else {
      setError('הסיסמה אינה נכונה');
    }
  }, [password, onDelete]);

  return (
    <>
      <IconButton onClick={handleFirstDialogOpen} size="small">
        <DeleteIcon className={classes.redTrash} />
      </IconButton>

      {/* First Dialog - Are you sure? */}
      <Dialog open={openFirstDialog} onClose={handleFirstDialogClose}>
        <DialogTitle>האם אתה בטוח?</DialogTitle>
        <DialogContent>
          <DialogContentText>מחיקה היא פעולה בלתי הפיכה. האם אתה בטוח שברצונך להמשיך?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFirstDialogClose} color="primary">
            ביטול
          </Button>
          <Button onClick={handleSecondDialogOpen} color="secondary">
            כן
          </Button>
        </DialogActions>
      </Dialog>

      {/* Second Dialog - Are you really sure? with Password */}
      <Dialog open={openSecondDialog} onClose={handleSecondDialogClose}>
        <DialogTitle>האם אתה באמת בטוח?</DialogTitle>
        <DialogContent>
          <DialogContentText>הכנס סיסמה לאישור מחיקה.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="סיסמה"
            type="password"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            error={!!error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSecondDialogClose} color="primary">
            ביטול
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            כן, אני בטוח
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Row = ({ data, index, style, refetch }) => {
  const client = data[index];

  const [deleteClient, { loading, error }] = useMutation(DELETE_CLIENT, {
    variables: { id: client.id },
    onCompleted: (data) => {
      console.log('Deleted successfully');
      console.log('Updated clients:', data.deleteClient.clients);
      console.log('Updated overview:', data.deleteClient.overview);
      if (typeof refetch === 'function') {
        refetch();
      }
    },
  });

  return (
    <ListItem
      key={client.id}
      style={style}
      selected={1 === client.id}
      button
      component="div"
    >
      <ListItemIcon
        style={{ pointerEvents: 'auto' }} // Enable clicks on the trash icon
      >
        <ClientTrash
          onDelete={deleteClient}
        />
      </ListItemIcon>
      <NavLink
        to={`/clients/${client.id}/loans`}
        style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}
      >
        <ListItemIcon>
          <ClientDot active={client.active} />
        </ListItemIcon>
        <ListItemText>
          {client.firstName} {client.lastName}
        </ListItemText>
      </NavLink>
    </ListItem>
  );
};


const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'block',
    flexGrow: 1,
  },
  searchItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

const ClientsList = () => {
  const [activeOnly, setActiveOnly] = useState(true);
  const [notActiveOnly, setNotActiveOnly] = useState(true);

  const [query, setQuery] = useState('');
  const { loading, error, data, refetch } = useQuery(CLIENTS_QUERY);
  console.log(555555555555, data);
  const changeQuery = useCallback(({ target: { value } }) => {
    setQuery(value);
  }, []);

  const onChangeActive = ({ target: { checked } }) => {
    setActiveOnly(checked);
  };

  const onChangeNotActive = ({ target: { checked } }) => {
    setNotActiveOnly(checked);
  };

  const clientsWithStatus = useMemo(() => {
    let clients = data?.clients ?? [];

    return clients.map((client) => ({
      ...client,
      active: !!data?.overview?.items?.find((x) => x === client.id),
    }));
  }, [data]);

  const filteredClients = useMemo(() => {
    if (!activeOnly && !notActiveOnly) {
      return [];
    }

    return clientsWithStatus.filter((client) => {
      const activeFilter = !activeOnly ? client.active !== true : true;
      const nonActiveFilter = !notActiveOnly ? client.active !== false : true;
      const queryFilter = query
        ? `${client.firstName.trim()} ${client.lastName.trim()}`.includes(query)
        : true;

      return activeFilter && nonActiveFilter && queryFilter;
    });
  }, [activeOnly, notActiveOnly, query, clientsWithStatus]);
  const history = useHistory();

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      if (filteredClients.length > 0) {
        const { id } = filteredClients[0];
        history.push(`/clients/${id}/loans`);
      }
    },
    [filteredClients],
  );
  const classes = useStyles();

  return (
    <>
      <Box>
        <ListItem
          className={classes.searchItem}
          component="form"
          onSubmit={handleSubmit}
        >
          <ListItemText>
            <InputBase
              placeholder="חיפוש לקוח"
              value={query}
              onChange={changeQuery}
            />
          </ListItemText>
          <IconButton type="submit" edge="end">
            <SearchIcon />
          </IconButton>
        </ListItem>
        <Divider />
        <ListItem dense>
          <FormControlLabel
            control={
              <Checkbox checked={activeOnly} onChange={onChangeActive} />
            }
            label="הצג לקוחות פעילים"
          />
        </ListItem>
        <ListItem dense>
          <FormControlLabel
            control={
              <Checkbox checked={notActiveOnly} onChange={onChangeNotActive} />
            }
            label="הצג לקוחות לא פעילים"
          />
        </ListItem>
      </Box>
      <List disablePadding className={classes.root}>
        <Divider component="li" />
        {error && <>Error network</>}
        {loading && (
          <>
            <ClientSkeleton width="60%" />
            <ClientSkeleton width="45%" />
            <ClientSkeleton width="75%" />
          </>
        )}
        {/* {clients.length === 0 && <div>EmptyState</div>} */}
        {query && filteredClients.length === 0 && (
          <ListItem>
            <ListItemText>לא נמצא תוצאות</ListItemText>
          </ListItem>
        )}
        <AutoSizer>
          {({ height, width }) => (
            <WindowedList
              height={height}
              itemCount={filteredClients.length}
              itemData={filteredClients}
              itemSize={35}
              width={width}
            >
              {(p) => <Row {...p} refetch={refetch} />}
            </WindowedList>
          )}
        </AutoSizer>
      </List>
      <Box component={Divider} />
      <Box sx={{ m: 3 }}>
        {data?.overview && data?.overview?.items && data?.clients && (
          <Typography>
            {`${data.clients.length} לקוחות`}
            {/* {`, `} */}
            <br />
            {`${data.overview.items.length} לקוחות פעילים`}
            {/* {`, `} */}
            <br />
            {data.overview.count} {'הלוואות פעילות'}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default ClientsList;
