import NotificationsIcon from '@material-ui/icons/Notifications';
import BarChartIcon from '@material-ui/icons/BarChart';

import CalculatorIcon from 'assets/icons/Calculator';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SettingsIcon from '@material-ui/icons/Settings';
import LockIcon from '@material-ui/icons/Lock';
import AddIcon from '@material-ui/icons/Add';

import BuildIcon from '@material-ui/icons/Build';
import AssignmentIcon from '@material-ui/icons/Assignment';
import TripOriginIcon from '@material-ui/icons/TripOrigin';

export default [
  {
    url: '/',
    name: 'תזכורות',
    icon: NotificationsIcon,
    role: 'VIEWER',
  },
  // {
  //   url: '/account',
  //   name: 'ניהול חשבון',
  //   icon: AccountBalanceIcon,
  //   routes: [
  //     {
  //       url: '/overview',
  //       name: 'כללי',
  //       icon: TripOriginIcon,
  //       disabled: true,
  //     },
  //   ],
  // },
  {
    url: '/bank',
    name: 'קופה',
    icon: BarChartIcon,
    role: 'ADMIN',
  },

  // ],
  // },
  // {
  //   url: 'utils',
  //   name: 'כלים',
  //   icon: BuildIcon,
  //   routes: [
  //     {
  //       url: '/calculators',
  //       name: 'מחשבונים',
  //       icon: CalculatorIcon,
  //       role: 'ADMIN',
  //     },
  //     {
  //       url: '/notes',
  //       name: 'פתקים',
  //       icon: AssignmentIcon,
  //       disabled: true,
  //       role: 'ADMIN',
  //     },
  //     {
  //       url: '/functions',
  //       name: 'פונקציות',
  //       icon: AssignmentIcon,
  //       disabled: true,
  //       role: 'ADMIN',
  //     },
  //   ],
  // },
  {
    url: '/calculators',
    name: 'מחשבונים',
    icon: CalculatorIcon,
    role: 'CALCULATOR',
  },
  {
    url: '/notes',
    name: 'פתקים',
    icon: AssignmentIcon,
    disabled: true,
    role: 'NOTES',
  },
  {
    url: '/settings',
    name: 'הגדרות מערכת',
    icon: SettingsIcon,
    role: 'ADMIN',
  },
  // {
  //   url: '/users',
  //   name: 'ניהול משתמשים',
  //   icon: LockIcon,
  //   role: 'OWNER',
  // },
];
